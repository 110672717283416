<template>
  <section class="flex items-start bg-white rounded-md">
    <div
      class="flex flex-col flex-1 shrink p-10 w-full basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full"
    >
      <h1
        class="flex-1 shrink gap-2.5 self-stretch w-full text-5xl font-medium tracking-tighter leading-none text-black max-md:max-w-full max-md:text-4xl"
      >
        Upload a financial audit
      </h1>
      <div class="flex flex-col mt-6 w-full max-md:max-w-full">
        <div class="flex flex-col w-full max-md:max-w-full">
          <div class="flex flex-col w-full max-md:max-w-full">
            <div
              class="flex flex-col justify-center px-6 py-4 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full"
            >
              <div class="flex flex-col w-full max-md:max-w-full">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/f26ce3f172e247d498b5c25e90861046/7721933cdd2807151898b19825aa47ac51f5b9a4c117acc7d71ef63db40b61b7?apiKey=f26ce3f172e247d498b5c25e90861046&"
                  alt=""
                  class="object-contain self-center w-10 shadow-sm aspect-square"
                />
                <div class="flex flex-col mt-3 w-full max-md:max-w-full">
                  <div
                    class="flex flex-wrap gap-1 justify-center items-start w-full text-sm leading-none max-md:max-w-full"
                  >
                    <span
                      class="overflow-hidden gap-1.5 self-stretch font-semibold text-violet-700"
                    >
                      Click to upload
                    </span>
                    <span class="text-slate-600">or drag and drop</span>
                  </div>
                  <p
                    class="mt-1 text-xs text-center text-slate-600 max-md:max-w-full"
                  >
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col justify-center items-end mt-6 w-full text-base font-semibold text-white max-md:max-w-full"
        >
          <button
            class="flex overflow-hidden gap-1.5 justify-center items-center px-5 py-3 bg-violet-500 rounded-lg border border-violet-500 border-solid shadow-sm"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/f26ce3f172e247d498b5c25e90861046/54cfb3f680f908d0afceadb4fea70abd38aeab88db9e12b18fe07b4cf641fbb6?apiKey=f26ce3f172e247d498b5c25e90861046&"
              alt=""
              class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            />
            <span class="self-stretch px-0.5 my-auto"
              >Analyze financial audit</span
            >
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
