import ToolsInvoice from '@/components/tools/invoice.vue'
import BaseHeader from '@/components/base/header.vue'
import BaseHelloWord from '@/components/base/hello-word.vue'
import BaseButton from '@/components/base/button.vue'
import BaseCtaCard from '@/components/base/cta-card.vue'
import BentoThreeColumnGrid from '@/components/bento/three-column-grid.vue'
import BentoTwoRowGrid from '@/components/bento/two-row-grid.vue'
import BentoTwoRowGridDark from '@/components/bento/two-row-grid-dark.vue'
import LogoCloudsSimpleDark from '@/components/logo-clouds/simple-dark.vue'
import ToolsCompanySearch from '@/components/tools/company-search.vue'
import BlogTipBox from '@/components/blog/TipBox.vue'
import PricingTable from '@/components/base/pricing-table.vue'
import MarginCalculator from '@/components/tools/margin-calculator.vue'
import BaseMathExpression from '~/components/base/base-math-expression.vue'
import MarginSchemeCalculator from '@/components/tools/margin-scheme-calculator.vue'
import AuditAnalyzer from '@/components/tools/audit-analyzer.vue'
import ImageSlider from '@/components/base/image-slider.vue'

export const registeredComponents = [
  {
    component: ImageSlider,
    name: 'Image Slider',
    inputs: [
      {
        name: 'slides',
        type: 'list',
        defaultValue: [{ title: 'Slide title' }],
        subFields: [
          {
            name: 'image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
              'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
          },
          {
            name: 'caption',
            type: 'string',
            defaultValue: 'Slide caption',
          },
          {
            name: 'title',
            type: 'string',
            defaultValue: 'Slide title',
          },
        ],
      },
    ],
  },
  {
    component: AuditAnalyzer,
    name: 'Audit Analyzer',
  },
  {
    component: MarginCalculator,
    name: 'Margin Calculator',
  },
  {
    component: MarginSchemeCalculator,
    name: 'Margin Scheme Calculator',
  },
  {
    component: PricingTable,
    name: 'Pricing Table',
  },
  {
    component: ToolsCompanySearch,
    name: 'Company Search',
  },
  {
    component: BentoThreeColumnGrid,
    name: 'Three column bento grid',
  },
  {
    component: BentoTwoRowGrid,
    name: 'Two row bento grid',
  },
  {
    component: BentoTwoRowGridDark,
    name: 'Two row bento grid dark',
  },
  {
    component: LogoCloudsSimpleDark,
    name: 'Logo cloud simple dark',
  },
  {
    component: BaseCtaCard,
    name: 'CTA Card',
    inputs: [
      {
        name: 'title',
        type: 'string',
        required: true,
      },
      {
        name: 'subheadline',
        type: 'string',
        required: false,
      },
      {
        name: 'buttonText',
        type: 'string',
        required: true,
      },
      {
        name: 'url',
        type: 'url',
        required: true,
      },
    ],
  },
  {
    component: BlogTipBox,
    name: 'Tip Box',
    inputs: [
      {
        name: 'title',
        type: 'string',
        defaultValue: 'TIP',
        required: true,
      },
      {
        name: 'text',
        type: 'richText',
        defaultValue: '<b>This text is bold</b>',
        required: false,
      },
    ],
  },
  {
    component: BaseButton,
    name: 'Button',
    inputs: [
      {
        name: 'text',
        type: 'string',
        required: true,
      },
      {
        name: 'url',
        type: 'url',
        required: true,
      },
      {
        name: 'variant',
        type: 'string',
        enum: ['primary', 'secondary', 'link-color'],
      },
    ],
  },
  {
    component: BaseHeader,
    name: 'Base Header',
  },
  {
    component: BaseHelloWord,
    name: 'Hello Word',
    inputs: [
      {
        name: 'Headline',
        type: 'string',
        defaultValue: 'World',
      },
    ],
  },
  {
    component: ToolsInvoice,
    name: 'Invoice Generator',
  },
  {
    component: BaseMathExpression,
    name: 'Math Expression',
    inputs: [
      {
        name: 'expression',
        type: 'string',
      },
    ],
  },
]
