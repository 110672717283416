<template>
  <section class="flex flex-col" ref="sliderContainer">
    <div
      class="flex flex-col items-center py-8 w-full text-base rounded-lg text-black text-opacity-60 max-md:max-w-full"
    >
      <div class="w-full">
        <div
          class="relative overflow-hidden"
          :style="{ height: containerHeight + 'px' }"
        >
          <transition name="slide">
            <div :key="currentSlide" class="absolute top-0 left-0 w-full">
              <img
                :src="slides[currentSlide]?.image?.url || slides[currentSlide]?.image"
                class="object-contain w-full rounded aspect-[1.7] max-md:max-w-full"
                :alt="slides[currentSlide]?.title"
                loading="lazy"
                @load="onImageLoad"
              />
            </div>
          </transition>
          <transition name="fade">
            <p
              :key="currentSlide"
              class="mt-2.5 max-md:max-w-full absolute bottom-0 left-0 w-full"
            >
              {{ slides[currentSlide]?.caption }}
            </p>
          </transition>
        </div>
      </div>
    </div>
    <div
      class="flex flex-wrap gap-2.5 justify-center items-center mt-2.5 w-full text-xl min-h-[30px] max-md:max-w-full"
    >
      <template v-for="(slide, index) in slides" :key="index">
        <button 
          @click="currentSlide = index"
          :class="[
            'self-stretch my-auto',
            currentSlide === index ? 'font-semibold text-pink-600' : 'font-medium text-violet-700 hover:text-ping-600'
          ]"
        >
          {{ slide.title }}
        </button>
        <span 
          v-if="index !== slides.length - 1" 
          class="font-medium text-violet-700"
        >
          /
        </span>
      </template>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'

// Props from Builder.io
const props = defineProps({
  slides: {
    type: Array,
    default: () => [],
  },
})

const currentSlide = ref(0)
const MAX_HEIGHT = 635
const CAPTION_PADDING = 40
const sliderContainer = ref(null)
const containerHeight = ref(0)

const updateHeight = () => {
  setTimeout(() => {
    const images = sliderContainer.value?.querySelectorAll('img')
    if (!images?.length) return

    let maxHeight = 0
    let loadedImages = 0

    images.forEach((img) => {
      if (img.complete) {
        const imageHeight = Math.min(img.height, MAX_HEIGHT)
        const totalHeight = imageHeight + CAPTION_PADDING
        maxHeight = Math.max(maxHeight, totalHeight)
        loadedImages++
      }
    })

    if (loadedImages === images.length) {
      containerHeight.value = maxHeight
    }
  }, 250)
}

const onImageLoad = () => {
  updateHeight()
}

// Reset current slide when slides change
watch(
  () => props.slides,
  () => {
    currentSlide.value = 0
    updateHeight()
  },
  { deep: true },
)

onMounted(() => {
  updateHeight()
  window.addEventListener('resize', updateHeight)
})

// Cleanup
onUnmounted(() => {
  window.removeEventListener('resize', updateHeight)
})
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out;
  position: absolute;
  width: 100%;
}

.slide-enter-from {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
