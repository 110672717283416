<!-- components/KatexDisplay.vue -->
<template>
  <div class="katex-container">
    <!-- Loading placeholder that matches the expected height -->
    <div v-if="isLoading" class="katex-placeholder">
      <div class="loading-skeleton"></div>
    </div>

    <!-- Actual KaTex content with fade transition -->
    <div
      v-show="!isLoading"
      ref="katexElement"
      v-katex="expression"
      class="katex-wrapper"
      @vue:mounted="handleMount"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

defineProps<{
  expression: string
}>()

const isLoading = ref(true)
const katexElement = ref<HTMLElement | null>(null)

// Handle the mounting of the KaTex element
const handleMount = () => {
  // Short timeout to ensure KaTex has rendered
  setTimeout(() => {
    isLoading.value = false
  }, 50)
}

// Reset loading state if component remounts
onMounted(() => {
  isLoading.value = true
})
</script>

<style scoped>
.katex-container {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.katex-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.katex-placeholder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loading-skeleton {
  width: 80%;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.1) 0%,
    rgba(190, 190, 190, 0.2) 50%,
    rgba(190, 190, 190, 0.1) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

:deep(.katex-display) {
  margin: 1em 0;
  width: 100%;
  text-align: center;
}

:deep(.katex) {
  text-align: center;
  display: flex;
  justify-content: center;
}

:deep(.align*) {
  width: auto;
  margin: 0 auto;
}
</style>
