<template>
  <form class="relative">
    <label
      for="default-search"
      class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >Search</label
    >
    <div class="relative">
      <div
        class="absolute w-full inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
      >
        <svg
          class="w-4 h-4 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        type="search"
        class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-500 focus:border-gray-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
        v-model="searchTerm"
        placeholder="Search companies..."
        required
      />
    </div>

    <div
      class="absolute bg-white rounded-lg max-h-96 overflow-y-auto shadow-lg ring-1 ring-black/5 dark:bg-gray-800 dark:"
    >
      <ul role="list" class="divide-y divide-gray-100">
        <li v-for="result in results" :key="result.id" class="flex">
          <NuxtLink
            :to="`/company/${result.data.id}`"
            class="flex justify-between gap-x-6 px-5 py-3 w-full hover:bg-gray-50 dark:hover:bg-gray-800"
          >
            <div class="flex min-w-0 gap-x-4">
              <div class="min-w-0 flex-auto">
                <p class="text-sm font-semibold leading-6 text-gray-900">
                  {{ result.data?.caption || result.name }}
                </p>
                <p
                  v-if="result.data?.properties?.address"
                  class="mt-1 truncate text-xs leading-5 text-gray-500"
                >
                  {{ getFirstValue(result.data.properties.address) }}
                </p>
              </div>
            </div>
            <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p class="text-sm font-semibold leading-6 text-gray-900">
                Reg. number
              </p>
              <p
                v-if="result.data?.properties?.registrationNumber"
                class="mt-1 text-xs leading-5 text-gray-500"
              >
                {{ getFirstValue(result.data.properties.registrationNumber) }}
              </p>
            </div>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </form>
</template>

<script setup>
import { ref, watch } from 'vue'
import { fetchEntries } from '@builder.io/sdk-vue'
import debounce from 'lodash/debounce'

const runtimeConfig = useRuntimeConfig()
const apiKey = runtimeConfig.public.builderIOApiKey

const model = 'companies'
const searchTerm = ref('')
const results = ref([])

const getFirstValue = (field) => {
  if (Array.isArray(field) && field.length > 0 && field[0].value) {
    return field[0].value
  }
  return field && typeof field === 'object' ? field.value : field
}
const searchCompanies = async () => {
  if (searchTerm.value.length < 2) {
    results.value = []
    return
  }

  try {
    const response = await fetchEntries({
      model,
      apiKey,
      query: {
        'data.caption': {
          $regex: searchTerm.value,
          $options: 'i',
        },
      },
      fields: [
        'data.caption',
        'name',
        'data.id',
        'data.properties.registrationNumber',
        'data.properties.address',
      ],
      limit: 5,
    })

    if (Array.isArray(response)) {
      results.value = response
    } else if (response && Array.isArray(response.results)) {
      results.value = response.results
    } else if (
      response &&
      response.data &&
      Array.isArray(response.data.results)
    ) {
      results.value = response.data.results
    } else {
      console.error('Unexpected response structure:', response)
      results.value = []
    }
  } catch (error) {
    console.error('Error fetching companies:', error)
    results.value = []
  }
}

const debouncedSearch = debounce(searchCompanies, 300)

watch(searchTerm, debouncedSearch)
</script>
