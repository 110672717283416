<template>
  <section class="flex flex-col items-center">
    <div
      class="flex flex-col items-center max-w-screen-xl border border-gray-300 rounded-md my-10"
    >
      <BasePricingHeader />
      <BasePricingItem />
    </div>
  </section>
</template>
