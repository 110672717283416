<template>
  <div class="flex flex-col pb-[782px] max-md:pb-24">
    <div class="flex flex-wrap gap-2.5 bg-white rounded-md max-md:max-w-full">
      <main
        class="flex flex-col self-start p-10 min-w-[240px] w-[985px] max-md:px-5 max-md:max-w-full"
      >
        <header
          class="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full"
        >
          <div class="flex flex-col max-w-[300px] min-w-[240px] w-[300px]">
            <div class="flex flex-col w-full">
              <div
                class="flex flex-col justify-center px-6 py-4 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5"
              >
                <div class="flex flex-col w-full">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/24e5a04896dabd2eb571a948826c745597d9637a4dcb6846abbcf45a16284c18?placeholderIfAbsent=true&apiKey=f26ce3f172e247d498b5c25e90861046"
                    alt=""
                    class="object-contain self-center w-10 shadow-sm aspect-square"
                  />
                  <div class="flex flex-col mt-3 w-full">
                    <div
                      class="flex gap-1 justify-center items-start w-full text-sm leading-none"
                    >
                      <div
                        class="overflow-hidden gap-1.5 self-stretch font-semibold text-violet-700"
                      >
                        Upload Logo
                      </div>
                      <div class="text-slate-600">or drag and drop</div>
                    </div>
                    <div class="mt-1 text-xs text-center text-slate-600">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1
            class="text-5xl tracking-tighter leading-none text-black max-md:text-4xl"
          >
            INVOICE
          </h1>
        </header>
        <section
          class="flex flex-wrap gap-10 items-start mt-10 w-full text-zinc-800 max-md:max-w-full"
        >
          <div
            class="flex flex-col flex-1 shrink text-sm leading-none basis-0 min-w-[240px]"
          >
            <h2 class="font-medium">Bill From:</h2>
            <address class="flex flex-col mt-2.5 w-full not-italic">
              <ToolsInvoiceInput
                name="company-name"
                placeholder="Company name"
              />
              <ToolsInvoiceInput name="street" placeholder="Street Address" />
              <ToolsInvoiceInput
                name="city"
                placeholder="ity, State/Province"
              />
              <ToolsInvoiceInput name="zip-code" placeholder="Zip Code" />
              <ToolsInvoiceInput name="country" placeholder="Country" />
            </address>
          </div>
          <div
            class="flex flex-col flex-1 shrink text-sm leading-none basis-0 min-w-[240px]"
          >
            <h2 class="font-medium">Bill To:</h2>
            <address class="flex flex-col mt-2.5 w-full not-italic">
              <ToolsInvoiceInput
                name="company-name"
                placeholder="Company name"
              />
              <ToolsInvoiceInput name="street" placeholder="Street Address" />
              <ToolsInvoiceInput
                name="city"
                placeholder="ity, State/Province"
              />
              <ToolsInvoiceInput name="zip-code" placeholder="Zip Code" />
              <ToolsInvoiceInput name="country" placeholder="Country" />
            </address>
          </div>
          <div
            class="flex flex-col flex-1 gap-10 shrink items-start text-sm font-medium leading-none basis-0 min-w-[240px]"
          >
            <div class="">
              <p>Invoice Number: 000001</p>
              <p class="mt-2.5 max-w-full whitespace-nowrap">
                Date:
                <NuxtTime :datetime="Date.now()" month="long" day="numeric" />
              </p>
            </div>
            <div>
              <h2 class="text-sm font-medium leading-none">Amount Due (EUR)</h2>
              <p
                class="mt-2.5 w-full text-4xl tracking-tighter leading-none whitespace-nowrap"
              >
                €0.00
              </p>
            </div>
          </div>
        </section>
        <section class="flex flex-col mt-10 w-full text-sm text-gray-500">
          <div
            v-for="(item, index) in lineItems"
            :key="index"
            class="grid gap-3"
            style="grid-template-columns: 5fr 1fr 1fr 1fr"
          >
            <ToolsInvoiceInput
              :name="item.description"
              :placeholder="item.description"
            />
            <ToolsInvoiceInput
              :name="item.quantity"
              :placeholder="item.quantity"
            />
            <ToolsInvoiceInput :name="item.price" :placeholder="item.price" />
            <ToolsInvoiceInput :name="item.vat" :placeholder="item.vat" />
          </div>
        </section>
        <section
          class="flex flex-wrap gap-10 justify-between items-start mt-10 w-full text-sm max-md:max-w-full"
        >
          <div
            class="flex gap-6 items-center font-medium leading-none text-slate-700"
          >
            <button
              class="overflow-hidden gap-2 self-stretch px-3.5 py-2 my-auto bg-white rounded-lg border border-gray-300 border-solid shadow-sm"
              @click="addLineItem"
            >
              Add line item
            </button>
          </div>
          <div class="flex flex-col min-w-[240px] w-[450px] max-md:max-w-full">
            <div
              class="flex flex-col justify-center items-start py-4 w-full font-medium leading-none text-violet-700 max-md:max-w-full"
            >
              <button class="flex overflow-hidden gap-2 items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a29e00ec830e98529907abc89fcb310d831ea665238e05e54a56ce99728e6a94?placeholderIfAbsent=true&apiKey=f26ce3f172e247d498b5c25e90861046"
                  alt=""
                  class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
                />
                <span class="self-stretch my-auto">Add discount</span>
              </button>
            </div>
            <div
              class="flex flex-col w-full leading-none whitespace-nowrap max-md:max-w-full"
            >
              <div
                class="flex gap-10 justify-between items-start px-0 py-4 w-full border-t border-b border-solid border-y-gray-300 max-md:max-w-full"
              >
                <div class="gap-2.5 self-stretch w-14 text-slate-800">
                  Subtotal
                </div>
                <div id="subtotal" class="text-black">0,00€</div>
              </div>
            </div>
            <div class="flex flex-col w-full leading-none max-md:max-w-full">
              <div
                class="flex gap-10 justify-between items-start px-0 py-4 w-full border-b border-solid border-b-gray-300 max-md:max-w-full"
              >
                <div class="gap-2.5 self-stretch w-14 text-slate-800">
                  Total Tax
                </div>
                <div id="total-tax" class="text-black">0,00€</div>
              </div>
            </div>
            <div
              class="flex flex-col w-full text-base font-semibold whitespace-nowrap max-md:max-w-full"
            >
              <div
                class="flex gap-10 justify-between items-start px-0 py-4 w-full border-b border-solid border-b-gray-300 max-md:max-w-full"
              >
                <div class="gap-2.5 self-stretch w-14 text-slate-800">
                  Total
                </div>
                <div id="total-price" class="text-black">0,00€</div>
              </div>
            </div>
          </div>
        </section>
        <section class="flex flex-col mt-10 w-full max-md:max-w-full">
          <div class="flex flex-col w-full min-h-[180px] max-md:max-w-full">
            <div class="flex flex-col flex-1 w-full max-md:max-w-full">
              <label
                for="notes"
                class="text-sm font-medium leading-none text-slate-700"
              >
                Notes
              </label>
              <textarea
                id="notes"
                class="overflow-hidden flex-1 shrink gap-2 px-3.5 py-3 mt-1.5 text-base leading-6 text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm size-full max-md:max-w-full"
                placeholder="Notes - any relevant information not covered, additional terms and conditions"
              ></textarea>
            </div>
          </div>
        </section>
      </main>
      <aside
        class="flex flex-1 shrink gap-2.5 justify-center items-start py-10 pr-7 pl-7 h-full text-base font-semibold whitespace-nowrap bg-gray-100 rounded-none basis-[25px] min-w-[240px] max-m-5"
      >
        <button
          class="flex overflow-hidden gap-1.5 justify-center items-center px-5 py-3 bg-white rounded-lg border border-gray-300 border-solid shadow-sm text-slate-700"
        >
          <span class="self-stretch px-0.5 my-auto">Preview</span>
        </button>
        <button
          class="flex overflow-hidden gap-1.5 justify-center items-center px-5 py-3 text-white bg-violet-500 rounded-lg border border-violet-500 border-solid shadow-sm"
        >
          <span class="self-stretch px-0.5 my-auto">Download</span>
        </button>
      </aside>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const lineItems = ref([
  {
    description: 'This is an invoice item',
    quantity: 0,
    price: 0,
    vat: '19%',
  },
  {
    description: 'This is an invoice item',
    quantity: 0,
    price: 0,
    vat: '19%',
  },
])

const addLineItem = () => {
  lineItems.value.push({
    description: 'New item',
    quantity: 0,
    price: 0,
    vat: '19%',
  })
}
</script>
