<template>
  <main class="flex flex-wrap bg-white rounded-md">
    <section
      class="flex flex-col flex-1 shrink self-start p-10 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full"
    >
      <form
        @submit.prevent="calculateMargin"
        class="flex flex-col w-full max-md:max-w-full"
      >
        <div class="flex flex-col w-full max-md:max-w-full">
          <label
            for="sellingPrice"
            class="text-sm font-medium leading-none text-slate-700"
          >
            Selling price
          </label>
          <input
            id="sellingPrice"
            v-model="sellingPrice"
            type="number"
            class="flex gap-2 items-center px-3 py-2 mt-1.5 w-full text-base text-gray-900 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
            required
          />
        </div>

        <!--
        <div class="flex flex-col mt-6 w-full font-medium max-md:max-w-full">
          <label for="sourcing" class="text-sm leading-none text-slate-700"
            >Sourcing</label
          >
          <div class="relative">
            <select
              id="sourcing"
              v-model="sourcing"
              class="flex overflow-hidden flex-wrap gap-2 items-center px-3.5 py-2.5 mt-1.5 w-full text-base text-gray-900 bg-white rounded-lg border border-gray-300 border-solid shadow-sm appearance-none max-md:max-w-full"
            >
              <option value="direct">Direct purchase</option>
              <option value="indirect">Indirect purchase</option>
            </select>
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"
            >
              <img
                loading="lazy"
                src=""
                alt=""
                class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
              />
            </div>
          </div>
        </div>
        -->

        <div class="flex flex-col mt-6 w-full max-md:max-w-full">
          <label
            for="costPrice"
            class="text-sm font-medium leading-none text-slate-700"
          >
            Cost Price
          </label>
          <input
            id="costPrice"
            v-model="costPrice"
            type="number"
            class="flex gap-2 items-center px-3 py-2 mt-1.5 w-full text-base text-gray-900 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
            required
          />
        </div>

        <div class="flex flex-col mt-6 w-full max-md:max-w-full">
          <label
            for="vat"
            class="text-sm font-medium leading-none text-slate-700"
          >
            Value added tax (%)
          </label>
          <input
            id="vat"
            v-model="vat"
            type="number"
            class="flex gap-2 items-center px-3 py-2 mt-1.5 w-full text-base text-gray-900 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
            required
          />
        </div>

        <div
          class="flex flex-col justify-center items-end mt-6 w-full text-base font-semibold text-white whitespace-nowrap max-md:max-w-full"
        >
          <button
            type="submit"
            class="flex overflow-hidden gap-1.5 justify-center items-center px-5 py-3 bg-violet-500 rounded-lg border border-violet-500 border-solid shadow-sm"
          >
            <span class="self-stretch px-0.5 my-auto">Calculate Margin</span>
          </button>
        </div>
      </form>
    </section>

    <section
      class="flex flex-col flex-1 shrink p-10 rounded-none border-l border-gray-300 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full"
    >
      <div
        class="flex flex-col p-5 w-full bg-emerald-50 rounded-md border border-emerald-600 border-solid max-md:max-w-full"
      >
        <h2 class="text-lg leading-loose text-neutral-700">Net margin</h2>
        <p class="mt-3 text-3xl font-semibold leading-none text-emerald-600">
          {{ netMargin.toFixed(2) }} EUR
        </p>
      </div>

      <div
        class="flex flex-wrap justify-between items-start py-2.5 mt-10 w-full border-b border-gray-300 max-md:max-w-full"
      >
        <div class="flex flex-col flex-1 shrink basis-0">
          <h3 class="text-lg leading-loose text-gray-400">Purchase Price</h3>
          <p class="mt-1.5 text-2xl font-medium leading-none text-black">
            € {{ parseFloat(costPrice).toFixed(2) }}
          </p>
        </div>
        <div class="flex flex-col flex-1 shrink basis-0">
          <h3 class="text-lg leading-loose text-gray-400">Selling Price</h3>
          <p class="mt-1.5 text-2xl font-medium leading-none text-black">
            € {{ parseFloat(sellingPrice).toFixed(2) }}
          </p>
        </div>
        <div class="flex flex-col flex-1 shrink basis-0">
          <h3 class="text-lg leading-loose text-gray-400">Tax</h3>
          <p class="mt-1.5 text-2xl font-medium leading-none text-black">
            € {{ tax.toFixed(2) }}
          </p>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

// Define reactive properties
const sellingPrice = ref(1000)
const costPrice = ref(400)
const vat = ref(19)
const tax = ref(0)
const netMargin = ref(0)

const calculateMargin = () => {
  // Convert inputs to numbers
  const selling = parseFloat(sellingPrice.value)
  const cost = parseFloat(costPrice.value)
  const vatRate = parseFloat(vat.value)

  // Calculate the margin (difference between selling and cost)
  const margin = selling - cost

  // Calculate VAT on the margin
  const taxAmount = (margin * vatRate) / (100 + vatRate) // Adjusted for UK scheme

  // Set the results
  tax.value = taxAmount
  netMargin.value = margin - taxAmount // Net margin after VAT deduction
}

// Calculate initial values on mount
onMounted(() => {
  calculateMargin()
})
</script>
