<template>
  <div class="w-full px-5">
    <table class="w-full text-left max-sm:hidden">
      <caption class="sr-only">
        Pricing plan comparison
      </caption>
      <colgroup>
        <col class="w-1/5" />
        <col class="w-1/5" />
        <col class="w-1/5" />
        <col class="w-1/5" />
      </colgroup>
      <tbody v-for="section in sections" :key="section.name" class="group">
        <tr>
          <th scope="colgroup" colspan="4" class="px-0 pb-0">
            <div class="py-3 text-sm/6 font-semibold text-gray-950">
              {{ section.name }}
            </div>
          </th>
        </tr>
        <tr
          v-for="feature in section.features"
          :key="feature.name"
          class="border-b border-gray-100 last:border-none"
        >
          <th scope="row" class="px-0 py-4 text-sm/6 font-normal text-gray-600">
            {{ feature.name }}
          </th>
          <td
            v-for="tier in tiers"
            :key="tier.name"
            class="p-4 max-sm:text-center"
          >
            <template v-if="typeof feature.tiers[tier.name] === 'string'">
              <span class="sr-only">{{ tier.name }} includes:</span>
              <span class="text-sm/6 text-gray-950">{{
                feature.tiers[tier.name]
              }}</span>
            </template>
            <template v-else>
              <svg
                v-if="feature.tiers[tier.name] === true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="inline-block size-6 fill-green-600"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clip-rule="evenodd"
                />
              </svg>

              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="inline-block size-6 fill-gray-400"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="sr-only">{{
                feature.tiers[tier.name] === true
                  ? `Included in ${tier.name}`
                  : `Not included in ${tier.name}`
              }}</span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
const tiers = [
  {
    name: 'Starter',
    description: 'Everything you need to get started.',
    priceMonthly: '$19',
    href: '#',
    highlights: [
      { description: 'Custom domains' },
      { description: 'Edge content delivery' },
      { description: 'Advanced analytics' },
      { description: 'Quarterly workshops', disabled: true },
      { description: 'Single sign-on (SSO)', disabled: true },
      { description: 'Priority phone support', disabled: true },
    ],
  },
  {
    name: 'Growth',
    description: 'All the extras for your growing team.',
    priceMonthly: '$49',
    href: '#',
    highlights: [
      { description: 'Custom domains' },
      { description: 'Edge content delivery' },
      { description: 'Advanced analytics' },
      { description: 'Quarterly workshops' },
      { description: 'Single sign-on (SSO)', disabled: true },
      { description: 'Priority phone support', disabled: true },
    ],
  },
  {
    name: 'Scale',
    description: 'Added flexibility at scale.',
    priceMonthly: '$99',
    href: '#',
    highlights: [
      { description: 'Custom domains' },
      { description: 'Edge content delivery' },
      { description: 'Advanced analytics' },
      { description: 'Quarterly workshops' },
      { description: 'Single sign-on (SSO)' },
      { description: 'Priority phone support' },
    ],
  },
]
const sections = [
  {
    name: 'Document Inteligence',
    features: [
      {
        name: 'Document runs',
        tiers: { Starter: '100', Growth: '500', Scale: '500' },
      },
      {
        name: 'Bills',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Expenses',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Cost for additional runs',
        tiers: {
          Starter: '€10,00 /for 100 documents',
          Growth: '€10,00 /for 100 documents',
          Scale: '€10,00 /for 100 documents',
        },
      },
    ],
  },
  {
    name: 'Core Modules',
    features: [
      {
        name: 'Vendor',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Customer',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Estimates',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Credit Notes',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
    ],
  },
  {
    name: 'Reports',
    features: [
      {
        name: 'Profit & Loss',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Balance Sheet',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Cashflow Statement',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'General Ledger',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Trial Balance',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
    ],
  },
  {
    name: 'Banking',
    features: [
      {
        name: 'Connect Bank Account',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Reconcile transactions',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
      {
        name: 'Multi Currency',
        tiers: { Starter: false, Growth: true, Scale: true },
      },
      {
        name: 'Manual Journal Entries',
        tiers: { Starter: false, Growth: true, Scale: true },
      },
      {
        name: 'Rules',
        tiers: { Starter: false, Growth: true, Scale: true },
      },
      {
        name: 'VAT Report',
        tiers: { Starter: true, Growth: true, Scale: true },
      },
    ],
  },
]
</script>
