<template>
  <section class="flex flex-wrap items-start border-b border-gray-300">
    <aside
      class="flex flex-col flex-1 shrink justify-between self-stretch p-8 bg-white border-r border-gray-300 basis-0 min-w-[240px] max-md:px-5"
    >
      <div class="flex flex-col w-full">
        <!-- 
        <p class="max-w-full text-base leading-6 text-gray-500 w-[206px]">
          Rated 4.8 / 5 stars of 500+ Reviews on G2
        </p>
       
        <div class="flex gap-2.5 items-center self-start mt-2">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/392b62837c73eb1bd02594b92e93dfd13a4b81d5c31b04349098763395793eeb?placeholderIfAbsent=true&apiKey=f26ce3f172e247d498b5c25e90861046"
            class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            alt="Star rating"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/392b62837c73eb1bd02594b92e93dfd13a4b81d5c31b04349098763395793eeb?placeholderIfAbsent=true&apiKey=f26ce3f172e247d498b5c25e90861046"
            class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            alt="Star rating"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/392b62837c73eb1bd02594b92e93dfd13a4b81d5c31b04349098763395793eeb?placeholderIfAbsent=true&apiKey=f26ce3f172e247d498b5c25e90861046"
            class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            alt="Star rating"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/392b62837c73eb1bd02594b92e93dfd13a4b81d5c31b04349098763395793eeb?placeholderIfAbsent=true&apiKey=f26ce3f172e247d498b5c25e90861046"
            class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            alt="Star rating"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/392b62837c73eb1bd02594b92e93dfd13a4b81d5c31b04349098763395793eeb?placeholderIfAbsent=true&apiKey=f26ce3f172e247d498b5c25e90861046"
            class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            alt="Star rating"
          />
        </div>
         -->
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex w-full">
          <fieldset class="w-full" aria-label="Payment frequency">
            <RadioGroup
              v-model="frequency"
              class="grid grid-cols-2 gap-x-1 rounded-full bg-gray-100 p-1 text-center text-xs font-semibold leading-5 text-black"
            >
              <RadioGroupOption
                as="template"
                v-for="option in frequencies"
                :key="option.value"
                :value="option"
                v-slot="{ checked }"
              >
                <div
                  :class="[
                    checked ? 'bg-gray-900 text-white' : '',
                    'cursor-pointer rounded-full px-2.5 py-2',
                  ]"
                >
                  {{ option.label }}
                </div>
              </RadioGroupOption>
            </RadioGroup>
          </fieldset>
        </div>
      </div>
    </aside>
    <div
      v-for="tier in tiers"
      :key="tier.id"
      class="flex flex-col flex-1 shrink justify-center p-8 text-base font-medium bg-white border-r border-gray-300 basis-0 min-w-[240px] max-md:px-5"
    >
      <div class="flex flex-col w-full">
        <h2 class="text-2xl leading-none font-semibold text-gray-900">
          {{ tier.name }}
        </h2>
        <p class="mt-2 leading-6 text-gray-500">
          {{ tier.description }}
        </p>
        <div
          class="flex gap-1 items-end mt-2 w-full text-lg whitespace-nowrap min-h-[90px]"
        >
          <span class="text-4xl font-semibold text-gray-900">
            {{ tier.price[frequency.value] }}
          </span>
          <span class="leading-loose text-gray-500">{{
            `/${frequency.value}`
          }}</span>
        </div>
        <a
          :href="tier.href"
          :aria-describedby="tier.id"
          class="flex overflow-hidden gap-1.5 justify-center items-center px-4 py-2.5 mt-2 w-full font-semibold text-white bg-pink-600 rounded-lg shadow-sm"
        >
          Get started
        </a>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref } from 'vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'

const frequencies = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'annually', label: 'Annually' },
]

const tiers = [
  {
    name: 'Standard',
    id: 'tier-starter',
    href: '#',
    featured: false,
    description: 'Everything you need to get started.',
    price: { monthly: '€47', annually: '€470' },
    highlights: [
      'Custom domains',
      'Edge content delivery',
      'Advanced analytics',
    ],
  },
  {
    name: 'Premium',
    id: 'tier-scale',
    href: '#',
    featured: false,
    description: 'Added flexibility at scale and a text.',
    price: { monthly: '€67', annually: '€670' },
    highlights: [
      'Custom domains',
      'Edge content delivery',
      'Advanced analytics',
      'Quarterly workshops',
      'Single sign-on (SSO)',
      'Priority phone support',
    ],
  },
  {
    name: 'Pay once',
    id: 'tier-growth',
    href: '#',
    featured: false,
    description: 'Pay once and use forever for a limited time.',
    price: { monthly: '€1720', annually: '€1720' },
    highlights: [
      'Custom domains',
      'Edge content delivery',
      'Advanced analytics',
      'Quarterly workshops',
    ],
  },
]

const frequency = ref(frequencies[0])
</script>
