<template>
  <section class="flex flex-wrap bg-white rounded-md">
    <div
      class="flex flex-col flex-1 shrink self-start p-10 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full"
    >
      <form @submit.prevent="calculateGrossProfit">
        <div class="flex flex-col w-full max-md:max-w-full">
          <div class="flex flex-col w-full max-md:max-w-full">
            <label
              for="sales"
              class="text-sm font-medium leading-none text-slate-700"
              >Sales (revenue)</label
            >
            <input
              id="sales"
              v-model="sales"
              type="number"
              class="flex gap-2 items-center px-3 py-2 mt-1.5 w-full text-base text-gray-900 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
              placeholder="Enter sales amount"
            />
          </div>
        </div>
        <div class="flex flex-col mt-6 w-full max-md:max-w-full">
          <div class="flex flex-col w-full max-md:max-w-full">
            <label
              for="cost"
              class="text-sm font-medium leading-none text-slate-700"
              >Cost to provide product or service</label
            >
            <input
              id="cost"
              v-model="cost"
              type="number"
              class="flex gap-2 items-center px-3 py-2 mt-1.5 w-full text-base text-gray-900 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
              placeholder="Enter cost amount"
            />
          </div>
        </div>
        <div
          class="flex flex-col justify-center items-end mt-6 w-full text-base font-semibold text-white max-md:max-w-full"
        >
          <button
            type="submit"
            class="flex overflow-hidden gap-1.5 justify-center items-center px-5 py-3 bg-violet-500 rounded-lg border border-violet-500 border-solid shadow-sm"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/f26ce3f172e247d498b5c25e90861046/5ee94a59b6746c9d11df8bdc012e78f5fbe56287d2022a002e326f1274cafa0a?apiKey=f26ce3f172e247d498b5c25e90861046&"
              alt=""
              class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            />
            <span class="self-stretch px-0.5 my-auto"
              >Calculate gross profit margin</span
            >
          </button>
        </div>
      </form>
    </div>
    <div
      class="flex flex-col flex-1 shrink justify-between p-10 text-gray-900 rounded-none border-l border-gray-300 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full"
    >
      <h2 class="text-3xl font-medium leading-none">Your gross margin</h2>
      <p
        class="mt-9 text-5xl font-semibold tracking-tighter leading-none max-md:text-4xl"
      >
        {{ grossMargin }}%
      </p>
      <p
        class="mt-9 text-lg text-zinc-800 underline-offset-auto max-md:max-w-full"
      >
        Cybooks serves up the numbers you need to track profits and manage your
        margins.
        <a
          href="https:/app.cybooks.com.cy/register/"
          class="font-semibold underline"
          target="_blank"
          rel="noopener noreferrer"
          >Try Cybooks for free</a
        >
      </p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      sales: 0,
      cost: 0,
      grossMargin: 0,
    }
  },
  methods: {
    calculateGrossProfit() {
      if (this.sales > 0) {
        this.grossMargin = Math.round(
          ((this.sales - this.cost) / this.sales) * 100,
        )
      } else {
        this.grossMargin = 0
      }
    },
  },
}
</script>
