<template>
  <section class="flex flex-col items-center bg-gray-700 max-sm:w-full">
    <div
      class="flex gap-8 justify-center max-w-screen-xl w-[1280px] max-md:max-w-full max-sm:w-full"
    >
      <div
        class="flex flex-col flex-1 shrink justify-center py-12 w-full basis-0 max-w-[1080px] min-w-[240px] max-md:max-w-full max-sm:px-5"
      >
        <header class="flex flex-col w-full text-base max-md:max-w-full mb-10">
          <p class="self-start text-center text-gray-300">
            Free Business Tools /
          </p>
          <h1
            class="mt-2 text-5xl font-medium tracking-tighter leading-none text-white max-md:max-w-full max-md:text-4xl"
          >
            Cyprus Company Search
          </h1>
          <p class="mt-2 mb-5 leading-6 text-white max-md:max-w-full">
            Find out if your name is already used and how likely it is to be<br />
            able to register your company name.
          </p>
        </header>
        <BaseSearchAutocomplete />
        <p class="text-sm text-gray-300 mt-2">
          For example, <span class="text-white">John Doe LTD</span>
        </p>
      </div>
    </div>
  </section>
</template>
